<template>
  <div>
    <div class="main-title">订单列表</div>
    <div class="content-list-page page-content">
      <div class="list-head-box">
        <div data-v-69874ad0="" class="list-head">
          <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
          <ul data-v-69874ad0="">
            <li data-v-69874ad0="">轮播图大小为：960*720</li>
            <li data-v-69874ad0="">排序值越高，在小程序首页显示越靠前</li>
          </ul>
        </div>
      </div>

      <div class="padding-t-sm padding-b-sm">
        <span class="margin-l-sm fl-l">
          <el-input v-model="searchData.sn" placeholder="订单号"></el-input>
        </span>

        <span class="margin-l-sm fl-l">
          <el-input v-model="searchData.paySn" placeholder="支付单号"></el-input>
        </span>

        <span class="margin-l-sm fl-l">
          <el-input v-model="searchData.nickname" placeholder="微信用户名称"></el-input>
        </span>

        <span class="margin-l-sm fl-l">
          <el-input v-model="searchData.phone" placeholder="手机号搜索"></el-input>
        </span>

        <span class="margin-l-sm fl-l">
          <el-select v-model="searchData.type" clearable placeholder="订单类型" size="small">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </span>

        <span class="margin-l-sm fl-l">
          <el-select v-model="searchData.status" clearable placeholder="订单状态" size="small">
            <el-option label="未支付" value="1"></el-option>
            <el-option label="已支付" value="2"></el-option>
            <el-option label="关闭" value="3"></el-option>
            <el-option label="支付失败" value="4"></el-option>
          </el-select>
        </span>

        <!-- <span class="margin-l-sm fl-l" >
                    <el-date-picker
                            size="small"
                            v-model="value2"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions">
                    </el-date-picker>
                </span> -->

        <span class="margin-l-sm fl-l">
          <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
        </span>

        <span class="fl-r">
            <el-button  @click="derive" type="primary" size="small" icon="el-icon-download">导出订单</el-button>
            <el-button size="small" icon="el-icon-upload">导入快递单号</el-button>
       
        </span>

        <div style="clear: both"></div>
      </div>

      <div class="table-content">
        <el-table :header-cell-style="{ padding: 0 }" :cell-class-name="rowItem" ref="singleTable" :data="tableData"
          highlight-current-row @current-change="handleCurrentChange" v-loading="loading"
          style="width: 100%; background: #fff">
          <el-table-column label="ID" property="id" width="80">
          </el-table-column>
          <el-table-column label="图片" width="100">
            <template slot-scope="scope">
              <el-image class="table-item-img" :src="imageUrl + scope.row.pic"
                :preview-src-list="[imageUrl + scope.row.pic]">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </template>
          </el-table-column>
          <el-table-column property="index" label="名称">
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
            </template>
          </el-table-column>

          <el-table-column property="index" label="订单号">
            <template slot-scope="scope">
              <span>{{ scope.row.sn }}</span>
            </template>
          </el-table-column>

          <el-table-column property="index" label="商品原价">
            <template slot-scope="scope">
              <span>{{ scope.row.price }}</span>
            </template>
          </el-table-column>

          <el-table-column property="index" label="优惠金额">
            <template slot-scope="scope">
              <span>{{ scope.row.discount_amount }}</span>
            </template>
          </el-table-column>

          <el-table-column property="index" label="实付金额">
            <template slot-scope="scope">
              <span>{{ scope.row.amount }}</span>
            </template>
          </el-table-column>

          <el-table-column property="user_name" label="收件人" >
          </el-table-column>
          <el-table-column property="phone" label="手机号" >
          </el-table-column>
          <el-table-column property="address" label="邮寄地址" >
          </el-table-column>
        
          <el-table-column property="created_at" label="创建时间" width="150">
          </el-table-column>

          <el-table-column property="user_name" label="用户昵称">
          </el-table-column>
          <el-table-column property="user_name" label="订单归属咨询师">
            <template slot-scope="scope">
              <span>{{ scope.row.counselor_name }}</span>
            </template>
          </el-table-column>
         


          <el-table-column property="address" label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1"><i></i>未支付</span>
              <span v-if="scope.row.status == 2" style="color: #67c23a"><i></i>已支付</span>
              <span v-if="scope.row.status == 3" style="color: #ccc"><i></i>关闭</span>
              <span v-if="scope.row.status == 4" style="color: #f56c6c"><i></i>支付失败</span>
            </template>
          </el-table-column>
          <el-table-column property="address" label="操作" width="200">
            <template slot-scope="scope">
              <span><el-link slot="reference" type="primary" @click="detail(scope.row)">详情</el-link></span>
              -
              <span><el-link slot="reference" type="primary" @click="
                selectOrderId = scope.row.id;
              dialogVisible = true;
              salesUserId = scope.row.counselor_id;
                                                                                  ">创建业绩单</el-link>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-r">
          <el-pagination small background :current-page="page" :page-sizes="[10, 20]" :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper" @size-change="setPageSize" @current-change="setPage"
            :total="total">
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>

    <el-dialog title="创建销售业绩单" :visible.sync="dialogVisible" append-to-body width="30%">
      <div>选择归属人：</div>
      <div style="padding: 10px 0">
        <el-select v-model="salesUserId" placeholder="请选择归属人" size="mini" clearable>
          <el-option :label="item.id + ':' + item.name" :value="item.id" v-for="item in salesUserList"
            :key="item.id"></el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="creatSalesOrder()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "template-list",
  data() {
    return {
      salesUserList: [],
      searchData: {},
      salesUserId: "",
      selectOrderId: "",
      dialogVisible: false,
      page: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      imageUrl: config.imageUrl,
      options: [
        {
          value: "1",
          label: "课程",
        },
        {
          value: "2",
          label: "会员卡",
        },
        {
          value: "3",
          label: "商铺",
        },
        {
          value: "4",
          label: "活动 ",
        },
        {
          value: "5",
          label: "团购",
        },
        {
          value: "6",
          label: "图书活动",
        },
        {
          value: "7",
          label: "打卡课程活动",
        },
      ],
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value2: "",
      tableData: [],
      currentRow: null,
    };
  },
  methods: {
    ...mapActions("store", ["getOrderList", "createdSalesOrder","exportOrder"]),
    ...mapActions("user", ["getUserList"]),
  async  derive(){
      let search = {
        ...this.searchData,
        page: this.page,
        pageSize: this.pageSize,
      };
      const { res_info } = await this.exportOrder(search);
      if(res_info == 'ok'){
        this.$message.success('添加到队列中')
      }else{
        this.$message.error('错误')
      }
      
    },
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    headeCellStyle({ row, rowIndex }) {
      return "{padding:0}";
    },
    rowItem() {
      return "row-item";
    },
    search() {
      this.tableData = [];
      this.page = 1;
      this.getList();
    },
    getList() {
      this.loading = true;
      let data = {
        ...this.searchData,
        page: this.page,
        pageSize: this.pageSize,
      };
      this.getOrderList(data).then((res) => {
        this.loading = false;
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },
    setStatus(row, rowIndex) {
      let _this = this;
      let data = {
        id: row.id,
        status: !row.status,
      };

      this.loading = true;
      this.setCourseStatus(data).then(
        (success) => {
          _this.tableData[rowIndex].status = success.data.status;

          _this.$message({
            message: "设置成功",
            type: "success",
          });
          this.loading = false;
        },
        (error) => {
          _this.$message({
            message: "设置失败",
            type: "error",
          });
          this.loading = false;
        }
      );
    },
    deleted(row, rowIndex) {
      let _this = this;
      this.loading = true;
      this.delCourse(row.id).then(
        (success) => {
          _this.getList();
          _this.$message({
            message: "删除成功",
            type: "success",
          });
        },
        (error) => {
          _this.$message({
            message: "删除失败",
            type: "error",
          });
        }
      );
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
    detail(row) {
      this.$router.push("/order/detail/" + row.id);
    },
    creatSalesOrder() {
      if (this.salesUserId === 0) return this.$message.error("请选择归属人");

      let data = {
        id: this.selectOrderId,
        counselorId: this.salesUserId,
      };
      console.log(data);
      this.createdSalesOrder(data).then((res) => {
        if (res.ret === 0) {
          this.$message.success("创建成功");
          this.dialogVisible = false;
        }
      });
    },
    getSalesUserList() {
      this.getUserList({ role: 5, status: 1 }).then((res) => {
        this.salesUserList = res.data.list;
      });
    },
  },
  mounted() {
    this.getList();
    this.getSalesUserList();
  },
};
</script>

<style></style>
